<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-1 dialogTitle-text">Custom Event Info</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleListCustomInfoModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <div style="text-align: justify; font-size: 16px">
              <hr
                style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
              />
              {{ this.customDetail.date_str }}
              {{ converTime(this.customDetail.start_time) }}-{{
                converTime(this.customDetail.end_time)
              }}
              <v-icon
                dark
                class="icons"
                color="#6B6B6B"
                size="22"
                @click="
                  toggleAddEventModal({
                    show: true,
                    type: 'view',
                    id: customDetail.id,
                  })
                "
              >
                mdi-pencil
              </v-icon>
              <v-icon
                dark
                class="icons"
                color="#6B6B6B"
                size="22"
                @click="
                  toggleDeleteCustomEventModal({
                    Delete: true,
                    id: customDetail.id,
                  })
                "
                v-if="this.adminAccess != 'Player'"
              >
                mdi-delete
              </v-icon>
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />

              <br />
              <div v-if="this.customDetail.player_confirmations.length != 0">
                <strong>Players: </strong>
                <div
                  v-for="(monthlySlot, index) in customplayeritems1"
                  :key="index"
                >
                  <tr class="students">
                    <td>{{ monthlySlot.player_name }}</td>
                    &nbsp;&nbsp;
                    <td
                      style="color: green; font-weight: 600"
                      v-if="monthlySlot.confirmation_status == 'confirmed'"
                    >
                      Confirmed
                    </td>
                    <td style="color: red; font-weight: 600" v-else>
                      Not Confirmed
                    </td>
                  </tr>
                </div>
              </div>
              <br />
              <div v-if="this.customDetail.player_confirmations.length == 0">
                <tr class="students">
                  <strong>Players: </strong>
                  <td
                    v-for="(item, i) in customplayeritems"
                    :key="i"
                    v-text="item.name"
                    class="student show"
                  ></td>
                </tr>
                <br />
              </div>
              <strong>Category: </strong>Custom Event
              <br />
              <br />
              <strong>Meeting Place: </strong>
              {{ this.customDetail.school }}
              <br />
              <strong>Meeting Time: </strong>
              {{ this.customDetail.meeting_time_str }}
              <br />
              <strong>Meeting Address: </strong>
              {{ this.customDetail.address }}
              <br />
              <strong>Description: </strong>
              <div v-html="this.customDetail.note"></div>
              <br />
              <strong>Contact Name: </strong
              >{{ this.customDetail.contact_name }}
              <br />
              <strong>Contact Number: </strong>{{ this.customDetail.phone }}
              <br />
              <strong>Sales Rep: </strong>
              {{ this.customDetail.sales_rep_name }}
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <add-event-modal></add-event-modal>
    <delete-custom-event-modal></delete-custom-event-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { API_PAID_EVENT_GET_DATA } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "ListCalendarManagement",
  components: {
    DeleteCustomEventModal: () =>
      import("../DeleteCustomEvent/DeleteCustomEventModal.vue"),
    AddEventModal: () => import("../AddEventModal/AddEventModal.vue"),
  },
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      loading: false,
      formLoading: false,
      customDetail: {},
      customplayeritems: [],
      customplayeritems1: [],
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "calendarManagement/getShowListCustomInfo",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleListCustomInfoModal({ show: value });
      },
    },
    type() {
      return this.$store.state.calendarManagement.listCustomInfoModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Task Added";
      } else {
        return "Task Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Task";
        case "edit":
          return "Edit Event";
        default:
          return "";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      toggleListCustomInfoModal: "calendarManagement/toggleListCustomInfoModal",
      toggleDeleteCustomEventModal: "calendarManagement/toggleDeleteModal",
      toggleAddEventModal: "calendarManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      this.getcustomeventDetail();
    },
    closeCallBackModal() {},

    getcustomeventDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.customDetail = res.data.paid_event_detail;
        if (this.customDetail.player_confirmations.length != 0) {
          this.customplayeritems1 = this.customDetail.player_confirmations;
        }
        if (this.customDetail.player_list != null) {
          this.customplayeritems = this.customDetail.player_list;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["paid_event_id"] =
        this.$store.state.calendarManagement.listCustomInfoModal.customID;
      Axios.request_GET(
        API_PAID_EVENT_GET_DATA,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    converTime(value) {
      if (value) {
        var temp = value.split(":");
        if (parseInt(temp[0]) > 12) {
          value = parseInt(temp[0]) - 12 + ":" + temp[1] + " PM";
        } else {
          value = temp[0] + ":" + temp[1] + " AM";
        }
      }

      return value;
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
